<template>
  <div>
    <c-card title="법규별 규제사항" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="editable"
            label="검증"
            icon="check"
            @btnClicked="verification" />
          <c-btn
            v-show="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="saveData"
            mappingType="PUT"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <!-- <div v-for="(item, idx) in regulConvert" :key="idx">
          {{idx}}
        </div> -->
        <div class="col-12 divchklaw" v-for="(item, idx) in bills" :key="idx">
          <div class="lawspan">{{item.regulationBillName}}</div>
          <q-checkbox
            v-for="(regul, _idx) in item.reguls" :key="_idx"
            class="customqcbox"
            dense
            color="orange-custom"
            true-value="Y"
            false-value="N"
            :disable="!editable"
            :label="regul.regulatedItemName"
            v-model="regul.val"
          />
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chemLaw',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
      }),
    },
    searchFlag: {
      type: Object,
      default: () => ({
        chemMsds: '',
      }),
    },
  },
  data() {
    return {
      reguls: [],
      bills: [],
      saveData: {
        mdmChemMaterialId: '',
        reguls: [],
      },
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
      popupOptions: {
        isFull: false,
        width: '100%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    'searchFlag.chemMsds'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.mam.chem.materialRegul.list.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.materialRegul.save.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmChemMaterialId: this.param.mdmChemMaterialId
      };
      this.$http.request((_result) => {
        this.reguls = _result.data;
        
        this.bills = [];
        if (this.reguls && this.reguls.length > 0) {
          this.bills = this.$_.map(this.reguls, (value) => {
            return {
              regulationBillCd: value['regulationBillCd'],
              regulationBillName: value['regulationBillName'],
              reguls: [],
            }
          });
          this.bills = this.$_.uniqBy(this.bills, (item) => {
            return item.regulationBillCd
          })
          this.$_.forEach(this.bills, bill => {
            this.$set(bill, 'reguls', this.$_.filter(this.reguls, { regulationBillCd: bill.regulationBillCd }))
          })
        }
      },);
    },
    verification() {
      this.popupOptions.title = '화학자재 상세';
      this.popupOptions.param = {
        mdmChemMaterialId: this.param.mdmChemMaterialId,
      };
      this.popupOptions.target = () => import(`${'./chemVerification.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    saveInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$set(this.saveData, 'mdmChemMaterialId', this.param.mdmChemMaterialId)
          this.$set(this.saveData, 'reguls', this.$_.filter(this.reguls, { val: 'Y' }))
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      Object.assign(this.$data.saveData, this.$options.data().saveData);
      this.getList();
    },
  }
};
</script>
<style>
  .lawspan {
    color: #0072c6 !important;
    font-size: 13px !important;
    font-weight: 600;
  }
  .divchklaw {
    margin-bottom: 10px;
  }
</style>
